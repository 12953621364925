<template>
  <div class="px-8 py-8 container" style="max-width: 1150px;margin:0 auto;">
    <div class="flex flex-wrap">
      <div class="w-full sm:w-4/5">
        <TitleComponent class="py-8" title="Koenen Test" size="large" />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="Koenen Apparatus Description"
              size="medium"
            />
            <a
              :href="require('../../assets/IPSeries/KoenenDetailed-min.png')"
              target="_blank"
            >
              <img
                class="m-auto"
                src="@/assets/IPSeries/KoenenDetailed-min.png"
              />
            </a>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              The purpose of the Koenen test is to determine the effect of
              heating a substance under defined confinement.
            </p>
            <TitleComponent
              class="py-8"
              title="Test Variations"
              size="medium"
            />
            <p class="p-light">
              The Koenen Test is used in both Test 1 (b) and Test 2 (b). In UN
              Test 1 (b) a 1 mm orifice plate is used. In UN Test 2 (b) there is
              a 2mm orifice plate.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Key Parameter</th>
                    <th>Objectives</th>
                    <th>Origin</th>
                    <th>Specs</th>
                  </tr>
                  <tr>
                    <td>Confining medium (steel tube)</td>
                    <td>
                      Vessel that can be used as evidence of sample reactivity
                      should the venting capacity of the orifice be exceeded
                      (excessive gas generation rate, orifice plugging, etc.)
                    </td>
                    <td></td>
                    <td>
                      Deep drawn from DC04, A620, or SPCEN sheet steel; 26.5 ±
                      1.5 grams mass, 75 °C 0.5mm length, 0.5 ± 0.05mm wall, and
                      29 ± 4 MPa quasi-static bursting pressure
                    </td>
                  </tr>
                  <tr>
                    <td>Orifice diameter</td>
                    <td>Provide varying degrees of venting capacity</td>
                    <td></td>
                    <td>1mm orifice</td>
                  </tr>
                  <tr>
                    <td>Heating rate</td>
                    <td>Provide a standard heating rate</td>
                    <td></td>
                    <td>
                      3.3 ± 0.3°K/sec for 27mL of silicone oil of apparent
                      density
                    </td>
                  </tr>
                  <tr>
                    <td>Maximum heating time</td>
                    <td>Sufficient time to react the material</td>
                    <td></td>
                    <td>5 minutes</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment</th>
                  </tr>
                  <tr>
                    <td>Damage to the steel tube and/or closing device</td>
                    <td>
                      Visual post-test examination of the tube and/or tube
                      fragments
                    </td>
                    <td>
                      Limiting diameter (LD) is the orifice at which an
                      explosion (three or more tube fragments) is obtained.
                      Violent effect if LD is 2 mm or greater: Class 1
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-8 pb-6" style="background-color:#f7f5f4;">
          <div class="w-full p-10">
            <TitleComponent class="pt-8" title="Setup Example" size="medium" />
            <a
              :href="require('../../assets/IPSeries/setup-koenen-min.jpg')"
              target="_blank"
            >
              <img
                style="width:60%;"
                class="m-auto"
                src="@/assets/IPSeries/setup-koenen-min.jpg"
              />
            </a>
          </div>
          <div class="w-full md:w-1/2 md:pr-2">
            <TitleComponent
              class="pt-8"
              title="No Explosion (-) Example Photo"
              size="medium"
            />
            <a
              :href="require('../../assets/IPSeries/nogo-koenen-min.jpg')"
              target="_blank"
            >
              <img
                style="width:75%;"
                class="m-auto"
                src="@/assets/IPSeries/nogo-koenen-min.jpg"
              />
            </a>
            <TitleComponent
              class="pt-8"
              title="Explosion (+) Example Photo"
              size="medium"
            />
            <a
              :href="require('../../assets/IPSeries/go-koenen-min.jpg')"
              target="_blank"
            >
              <img
                style="width:75%;"
                class="m-auto"
                src="@/assets/IPSeries/go-koenen-min.jpg"
              />
            </a>
          </div>
          <div class="w-full md:w-1/2 md:pl-2">
            <TitleComponent
              class="pt-8"
              title="No Explosion (-) Example Video"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166129503?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166129503"
              allow="autoplay; fullscreen"
              id="player_1"
              name="fitvid0"
            ></iframe>
            <TitleComponent
              class="pt-8"
              title="Explosion (+) Example Video"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166129504?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166129504"
              allow="autoplay; fullscreen"
              id="player_2"
              name="fitvid1"
            ></iframe>
          </div>
        </div>
      </div>
      <UnSideBar class="w-full sm:w-1/5"></UnSideBar>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import UnSideBar from "@/components/UnSideBar";

export default {
  components: {
    TitleComponent,
    UnSideBar
  }
};
</script>

<style lang="scss"></style>
